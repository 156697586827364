import {
  cloud,
  file_contract,
  file_loupe,
  office_buildings,
} from '@dnb/eufemia/icons';
import { isInternalUser } from '@portals/shared-frontend/utils';

import Tile from '@/components/Tile';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { useUser } from '@/hooks/useUser';

import ServicePage from '../../components/ServicePage';

export default function ApiExplorer() {
  const { user, isLoading: userLoading } = useUser();
  const isDnbUser = isInternalUser(user?.email);
  const { featureFlags } = useFeatureFlags();
  return (
    <ServicePage title="API Explorer">
      <Tile
        icon={file_contract}
        linkTo="/explorer/apis/regulatory"
        title="Regulatory APIs"
      />
      <Tile
        icon={cloud}
        linkTo="/explorer/apis/commercial"
        title="Commercial APIs"
      />
      {isDnbUser && !userLoading && (
        <Tile
          icon={office_buildings}
          linkTo="/explorer/apis/internal"
          title="Internal APIs"
        />
      )}
      {featureFlags.ENABLE_API_REVIEW_PAGE && isDnbUser && (
        <Tile
          description="Visible to API custodians only."
          icon={file_loupe}
          linkTo="/explorer/apis/review"
          title="APIs in review"
        />
      )}
    </ServicePage>
  );
}
