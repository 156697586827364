import Container from '@/components/Container';
import HeroPage from '@/components/HeroPage';
import PageMarkdownContent from '@/components/PageMarkdownContent';

export default function GettingStartedApiProviders() {
  return (
    <HeroPage
      heroIllustration={require('@/illustrations/DNB_Supergraphics_front_page.avif?url')}
      noContainer
      subtitle="Follow these steps to publish your APIs"
      title="Getting started for API providers"
    >
      <Container centered>
        <PageMarkdownContent
          path={`/content/tpp/${DEVELOPER_PORTAL_TEAM_ID}/api-providers`}
        />
      </Container>
    </HeroPage>
  );
}
